import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

class PrivacyPage extends React.Component {
  componentDidMount() {
    navigate('/app/redeem-gift-code');
  }

  render() {
    return (
      <Layout>
        <SEO title="Sidewall Club - Redeem Gift Code" />
        <section className="bg-white pt-6 px-4 md:px-0">
        </section>
      </Layout>
    );
  }
}

export default PrivacyPage;
